

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { GrDocumentPdf } from "react-icons/gr";
import { FaFilePdf } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { BsFillCreditCardFill } from "react-icons/bs";

import { FaRegCalendarAlt } from "react-icons/fa";
import { FaDownload } from "react-icons/fa";

import pdf from '../../src/assets/img/pdf.svg'
import { IoIosMail } from "react-icons/io";
import { FaCloudDownloadAlt } from "react-icons/fa";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';


import { IoIosArrowBack } from "react-icons/io";
import { IoMdPersonAdd } from "react-icons/io";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { FaSearch } from "react-icons/fa";
// import { IoMdPersonAdd } from "react-icons/md";
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from "react-toastify";


const CustomInput = ({ value, onClick }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    placeholder="dd/mm/yyyy"
    readOnly
    className="form-control"
  />
);

const CustomInputt = ({ value, onClick }) => (
  <input
    type="text"
    value={value}
    onClick={onClick}
    placeholder="dd/mm/yyyy"
    readOnly
    className="form-control"

  />
);



export default function Salaryslip() {
  const [data, setData] = useState([]);
  // console.warn(data)
  const [baseurl, setbaseurl] = useState();
  // const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  console.warn()
  const navigate = useNavigate();
  const [AllEmployee, setAllEmployee] = useState('');
  const [ActiveEmployee, setActiveEmployee] = useState('');
  const [InactiveEmployee, setInactiveEmployee] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const broker_id = localStorage.getItem("smalladmins_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const credit = localStorage.getItem("credit");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // alert(credit)

  const [totelsalery, settotelsalery] = useState('');
  const [MonthlySalary, setMonthlySalary] = useState('');
  const [TotalMonthlySalary, setTotalMonthlySalary] = useState('');
  // console.warn("daku",  MonthlySalary)

  //  alert(MonthlySalary)
  const [mounthysalry, setmounthysalry] = useState('');
  console.warn("daku",  mounthysalry)
  const [thismounthysalry, setthismounthysalry] = useState('');
  console.warn(mounthysalry)
  const [Name, setName] = useState('');
  const [email, setemail] = useState('');
  // const [Name, setName] = useState('');
  // alert(Name)


  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);
      formData.append("emp_id", userid);
      const token = localStorage.getItem("bear_token");
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-payslip-list`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Logging response and setting data
      console.warn("Response Data:", response.data.data);

      // Check if response.data.data is an array before setting state
      if (Array.isArray(response.data.data)) {


        setLoading(false);

        setData(response.data.data);
        settotelsalery(response.data.TotalEmployeeSalarySlip);
        setMonthlySalary(response.data.MonthlySalary);
        setTotalMonthlySalary(response.data.TotalMonthlySalary);
        // setmounthysalry(response.data.data[0].TotalEmployeeSalarySlip);
        setName(response.data.data[0].emp_name);
        setemail(response.data.data[0].email);


        setmounthysalry(response.data.TotalEmployeeSalarySlip);

        console.warn(response.data.data)
      } else {
        console.error(
          "Data received from API is not an array:",
          response.data.data
        );
      }
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  // const filteredData = data.filter((item) => {
  //   const earnings = item.trnx_id;
  //   return earnings && earnings.toString().toLowerCase().includes(searchTerm.toLowerCase());
  // });




  // const totalPages = Math.ceil(data.length / itemsPerPage);
  // const startIndex = (currentPage - 1) * itemsPerPage;
  // const endIndex = startIndex + itemsPerPage;
  // const paginatedData = data.slice(startIndex, endIndex);
  // console.warn(paginatedData)

  // First, filter the data based on searchTerm

  // useEffect(() => {
  //   setCurrentPage(1); 
  // }, [searchTerm]);


  const filteredData = data.filter (
    (item) =>
      item.trnx_id &&
      item.trnx_id.toLowerCase().includes(searchTerm.toLowerCase())
  );
     console.warn("jhgjhgjh",filteredData)

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredData.slice(startIndex, endIndex);






  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleDownload = () => {
    downloadFile(fromDate, toDate);
  };
  const handlesrarch = () => {
    srarch(fromDate, toDate);
  };




  const downloadFile = async (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "The to date must be a date after or equal to from date",
        color: "white",
        background: 'transparent',
      });
      // toast.error("Please choose a valid date!");
      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-filetrByDate-payslip-PDF`,
        {
          params: {
            from_date: fromDate,
            to_date: toDate,
            smalladmins_id: broker_id,
            emp_id: userid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: "application/pdf", // Specify the accept header for PDF response
          },
          responseType: "blob", // Set the responseType to 'blob' to receive binary data
        }
      );

      // Check if the response is a PDF file
      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "Employee_Salary_Filter_details.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Data not found!",
          color: "white",
          background: 'transparent',
        });
        // toast.error("Data not found!");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);

      if (error.response && error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "The to date must be a date after or equal to from date.",
          color: "white",
          background: 'transparent',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "An error occurred while fetching data.",
          color: "white",
          background: 'transparent',
        });
        // toast.error("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };






  const srarch = async (fromDate, toDate) => {
    if (!fromDate || !toDate) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "The to date must be a date after or equal to from date",
        color: "white",
        background: 'transparent',
      });

      setLoading(false);
      return;
    }

    try {
      const token = localStorage.getItem("bear_token");

      if (!token) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "No token found. Please log in.",
          color: "white",
          background: 'transparent',
        });
        // toast.error("No token found. Please log in.");
        setLoading(false);
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}small-admins-selfemp-filetrByDate-payslip`,
        null,
        {
          params: {
            from_date: fromDate,
            to_date: toDate,
            smalladmins_id: broker_id,
            emp_id: userid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json", // Typically JSON is used. Adjust if necessary.
          },
        }
      );

      console.warn("Response Data:", response.data.data);

      // Check if response.data.data is an array before setting state
      if (Array.isArray(response.data.data)) {
        setData(response.data.data);
        console.warn(response.data.data);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Data not found!",
          color: "white",
          background: 'transparent',
        });
        // toast.error("Data not found!");
      }
    } catch (error) {
      console.error("Error fetching transactions:", error);

      if (error.response && error.response.status === 401) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "The to date must be a date after or equal to from date.",
          color: "white",
          background: 'transparent',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "An error occurred while fetching data.",
          color: "white",
          background: 'transparent',
        });
        // toast.error("An error occurred while fetching data.");
      }
    } finally {
      setLoading(false);
    }
  };









  // Call the downloadFile function




  const Delete = async (id) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });
      if (result.isConfirmed) {
        setLoading(true);
        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", id);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}delete-small-admins-customers`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;
        if (data === 1) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  const handleStatusChange = async (business_id, currentStatus) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, change status!",
      });

      if (result.isConfirmed) {
        setLoading(true);

        const formData = new FormData();
        formData.append("smalladmins_id", broker_id);
        formData.append("cust_id", business_id);
        formData.append("status", currentStatus === 1 ? 0 : 1);

        const config = {
          method: "post",
          url: `${process.env.REACT_APP_API_URL}customer-small-admins-update-status`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        };

        const response = await axios(config);
        const data = response.data.status;

        if (data === 1) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }

        setLoading(false);
      }
    } catch (error) {
      console.error("Error changing status:", error);
      toast.error("There was an error changing the status.");
      setLoading(false);
    }
  };



  const handleClick = (id) => {
    localStorage.setItem("Addplan_id", id);
    navigate("/Add-More-plan");
  };
  const handleClickadd = (id) => {
    navigate("/Add-admins-customer");


  };
  const handleEditCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetailsedit");
  };


  const handleviveCustomerClick = (cust_id) => {
    localStorage.setItem('customer_id', cust_id);
    navigate("/Custumerdetails");
  };




  const [BaseUrl, setBaseUrl] = useState();
  const [profile, setprofile] = useState();
  useEffect(() => {
    getProfileimge();
  }, []);


  const getProfileimge = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);
      console.warn(response)
      const data = response.data.data.profile;
      setprofile(data)
      const data1 = response.data.image_url;
      setBaseUrl(data1)
      console.warn(data1)



    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };




  const handleviveCustomerClickSalaryslipvies = (id) => {
    console.warn(id)
    localStorage.setItem('id', id);
    navigate("/Salaryslipvies");
  };

  const capitalizeWords = (str) => {
    if (!str) return ""; // return an empty string if str is undefined or null
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };


  if (loading) {
    return <div>
      <div id="preloader">
        <div id="loading-center">
          <div className="loader">
            <div className="waviy">
              <img
                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                alt="Loader image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }





  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)"
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="mb-0">Employee  Salary   Analytics</h2>
            </div>



            <div className="col-lg-6 gap-2 d-flex justify-content-end align-items-center">
              {/* <Button className="gap-1 d-flex justify-content-end align-items-center" variant="info"><BsFillCreditCardFill />
                {credit}</Button>{' '} */}

              <Link to='/Home' className="linkWithoutUnderline">
                <Button variant="success">Back</Button>{' '}
              </Link>
            </div>






          </div>
        </div>



      </div>





      <div className="row g-3 mb-3 d-f">
        <div className="col-md-4 col-xxl-3">
          <Link className="">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2 d-flex align-items-center">
                  Total  Salary
                </h5>
              </div>
              <div className="card-body d-flex justify-content-start align-items-center" >

                <FaRupeeSign className="fs-4 text-success" />
                <p className="font-sans-serif lh-1 mb-1 fs-5 text-success">
                  {totelsalery}
                </p>
              </div>
            </div>

          </Link>
        </div>



        <div className="col-md-4 col-xxl-3">


          <Link className="">
            <div className="card h-md-100 ecommerce-card-min-width">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> Monthly Salary</h5>

              </div>
              <div className="card-body d-flex justify-content-start align-items-center" >

                <FaRupeeSign className="fs-4 text-primary" />
                <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                  {MonthlySalary}

                </p>
              </div>
            </div>

          </Link>
        </div>


        <div className="col-md-4 col-xxl-3">
          <Link className=" ">

            <div className="card h-md-100">
              <div className="card-header pb-0">
                <h5 className="mb-0 mt-2"> This Monthly</h5>
              </div>
              <div className="card-body d-flex justify-content-start align-items-center" >

                <FaRupeeSign className="fs-4 text-info" />
                {/* <p className="font-sans-serif lh-1 mb-1 fs-5  text-info">
                  {InactiveEmployee}0
                </p> */}
                {/* <p className="font-sans-serif lh-1 mb-1 fs-5 text-info">
                  {}0
                </p> */}
                <p className="font-sans-serif lh-1 mb-1 fs-5 text-primary">
                  {TotalMonthlySalary}
                </p>
              </div>

            </div>
          </Link>
        </div>
      </div>



      <div className="card mb-3">

        <div className="card-body position-relative">


          <div className="row d-flex justify-content-end">


            <div className="col-md-12  d-flex justify-content-between">


              <div className="d-flex justify-content-end gap-3 align-items-center">

                <h2 className="mb-0">Employee  Salary   Details</h2>


              </div>
              {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Salary All Pay Ship.pdf </Tooltip>}>
                <span className="d-inline-block">
                  <button className="align-items-center btn-primary d-flex btn" onClick={ALLdownloadFile}><img src={pdf} alt="pdf" width={20} />
                    Download PDF
                  </button>
                </span>
              </OverlayTrigger> */}

            </div>
          </div>

        </div>
      </div>


      <div className="card mb-3">

        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-4 ">

                  <input
                    className="form-control  shadow-none search  p-1.8"
                    type="search"
                    placeholder="Search by TXN "
                    aria-label="search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />



                </div>



                <div className="col-md-4">

                  <DatePicker
                    className="w-100"
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    customInput={<CustomInput />}
                  />


                </div>

                <div className="col-md-4">



                  <DatePicker
                    className="w-100"
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    customInput={<CustomInputt />}
                  />


                </div>
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-end gap-5">

              <button className="btn btn-info" onClick={handlesrarch}>   Search</button>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Download Statement.pdf </Tooltip>}>
                <span className="d-inline-block">

                  <button
                    className="buttonfordownload"
                    onClick={handleDownload}
                  >
                    <FaCloudDownloadAlt className="fs-4 text-dark" />
                  </button>
                </span>
              </OverlayTrigger>
            </div>

          </div>
          {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Salary Fitter Pay Ship.pdf</Tooltip>}>
            <span className="d-inline-block">
              <button className="buttonfordownload  d-flex" onClick={handleDownload}>
                <img src={pdf} alt="pdf" width={40} />
              </button>
            </span>
          </OverlayTrigger> */}



        </div>

      </div>




      {/* <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">

          <div className="row">

            <div className="row">
              <div className="col-md-6 d-flex gap-1 align-items-center">
                <img className="rounded-circle"
                  src={
                    `${BaseUrl}/${profile}`
                  }
                  alt="" width={30} />
                <h5 className="mb-0">{Name} </h5>
              </div>
              <div className="col-md-6 d-flex justify-content-end align-items-center gap-1">
                <IoIosMail className="fs-5  text-primary" />

                <h5 className="mb-0 text-primary">{email} </h5>
              </div>


            </div>

          </div>
        </div>
      </div> */}




      <div
        className="card"
        id="ticketsTable"
        data-list='{"valueNames":["client","subject","status","priority","agent"],"page":11,"pagination":true,"fallback":"tickets-table-fallback"}'
      >
        {/* <div className="card-header justify-content-between d-flex border-bottom border-200">
          <div className="justify-content-end d-flex w-100">
            <form>
              <div className="input-group input-search-width">
                <input
                  className="form-control form-control-sm shadow-none search"
                  type="search"
                  placeholder="Search by name"
                  aria-label="search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div> */}
        {loading && (
          <div id="preloader">
            <div id="loading-center">
              <div className="loader">
                <div className="waviy">
                  <img
                    src="https://swaninvestment.in/frontant/assets/img/loader.png"
                    alt="Loader image"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className="card-body p-0">
          <div className="table-responsive scrollbar">


            <table className="table table-sm mb-0 fs-10 table-view-tickets">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  <th className="text-800 sort align-middle">  Name  </th>
                  <th className="text-800 sort align-middle">Transaction Amount </th>
                  <th className="text-800 sort align-middle">Date</th>
                  <th className="text-800 sort align-middle">Action</th>

                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {data.map((item, index) => (
                  <tr>
                    <td>{startIndex + index + 1}</td>
                    <td>{item.emp_name}</td>

                    <td>{item.net_payable_salary}</td>
                    <td>{item.salary_slip_date}</td>
                    <td>      <Button variant="warning">View</Button>{' '} </td>

                  </tr>
                ))}

              </tbody>
            </table>

          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div> */}
        <div className="card-body p-0">
          <div className="table-responsive scrollbar">
            <table className="table ">
              <thead className="bg-body-tertiary">
                <tr>
                  <th className="text-800 sort align-middle">Sr.</th>
                  {/* <th className="text-800 sort align-middle">  Name  </th> */}
                  <th className="text-800 sort align-middle">Transaction  Id </th>
                  <th className="text-800 sort align-middle">Basic Salary  </th>
                  <th className="text-800 sort align-middle"> Net Payable Salary </th>
                  <th className="text-800 sort align-middle">Date</th>
                  {/* <th className="text-800 sort align-middle">status</th> */}
                  <th className="text-800 sort align-middle">Action</th>
                  {/* <th className="text-800 sort align-middle">Action</th> */}
                </tr>
              </thead>
              <tbody className="list" id="table-ticket-body">
                {paginatedData.length > 0 ? (
                  paginatedData.map((item, index) => (
                    <tr>
                      <td style={{ Border: "1px solid #ccc" }}>{startIndex + index + 1}</td>
                      <td style={{ Border: "1px solid #ccc" }}>{capitalizeWords(item.trnx_id)}</td>

                      <td style={{ Border: "1px solid #ccc" }}>{item.basic_salary}</td>
                      <td style={{ Border: "1px solid #ccc", color:"green" }}>{item.net_payable_salary}</td>
                      <td style={{ Border: "1px solid #ccc" }}>{item.salary_slip_date}</td>
                      <td >
                        <div className="d-flex gap-1 action_button">
                          <OverlayTrigger
                            key="View"
                            placement="top"
                            overlay={<Tooltip id={`View`}>View</Tooltip>}
                          >
                            <button type="button" style={{ "--i": "#07bc0c" }} onClick={() => handleviveCustomerClickSalaryslipvies(item.id)}>
                           
                              <FaEye className="color_link" />
                            </button>
                          </OverlayTrigger>
                        </div></td>


                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="12" className="text-center py-4">
                      No data found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

          </div>
          {totalPages > 1 && (
            <div className="pagination mt-3 mb-3 mx-3">
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                <IoIosArrowBack />
              </button>
              {[...Array(totalPages).keys()].map((pageNumber) => (
                <button
                  key={pageNumber + 1}
                  className={`btn btn-sm mx-1 ${currentPage === pageNumber + 1
                    ? "btn-light text-primary"
                    : "btn-light"
                    }`}
                  onClick={() => handlePageChange(pageNumber + 1)}
                >
                  {pageNumber + 1}
                </button>
              ))}
              <button
                className="btn btn-sm btn-light"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                <IoIosArrowForward />
              </button>
            </div>
          )}
        </div>

      </div>
      <ToastContainer />
    </>
  );
}
