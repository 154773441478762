import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FaFilePdf, FaRegEdit, FaEye, FaSearch, FaCloudDownloadAlt } from "react-icons/fa";
import { MdCurrencyRupee, MdOutlineAccountBalanceWallet, MdDelete, MdOutlinePendingActions, MdOutlineSmsFailed, MdArrowOutward } from "react-icons/md";
import { IoMdAdd, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Button, Row } from "react-bootstrap";
import Swal from "sweetalert2";
import { GrTransaction } from "react-icons/gr";
import { FaDiagramSuccessor } from "react-icons/fa6";
import { FcDownload } from "react-icons/fc";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import userprofile from "../assets/img/team/2.jpg";
import pdf from "../assets/img/team/pdf.png";




export default function Report() {

  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const custumerdetails_id = localStorage.getItem("custumerdetails");
  const emp_id = localStorage.getItem("employee_id");
  const userid = localStorage.getItem("userid");
  const token = localStorage.getItem("bear_token");
  const broker_id = localStorage.getItem("smalladmins_id");
  const customer_id = localStorage.getItem("customer_id");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Function to download Form 16B PDF
// Function to display Form 16B PDF in the browser
const downloadFileA = async () => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You are about to download Form 16A.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, download it!",
    cancelButtonText: "No, cancel",
    confirmButtonColor: '#2365af',
    cancelButtonColor: '#d33',
    background: '#fff',
    color: "#fff",
  });

  // If user confirms, proceed with the download
  if (result.isConfirmed) {
    try {
      setLoading(true); // Start loading state
      const token = localStorage.getItem("bear_token");
      const url = `${process.env.REACT_APP_API_URL}small-admins-selfemp-form-16A-PDF?smalladmins_id=${broker_id}&emp_id=${userid}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
        responseType: "blob",
      });

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = window.URL.createObjectURL(blob);

        window.open(fileURL, "_blank"); // Open in a new tab
        console.warn("ramrajjjjji",fileURL, "_blank")

//         Swal.fire(
// {
//           title: "Are you sure?",
//     text: "Your Form 16A has been downloaded.",
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonText: "Yes, download it!",
//     cancelButtonText: "No, cancel",
//        } );
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while loading the PDF file.",
      });
    } finally {
      setLoading(false); // Stop loading state
    }
  } else {
    // User clicked 'Cancel'
    // Swal.fire("Cancelled", "The download was cancelled.", "info");
  }
};



const downloadFileB = async () => {
  const result = await Swal.fire({
    title: "Are you sure?",
    text: "You are about to download Form 16B.",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Yes, download it!",
    cancelButtonText: "No, cancel",
    confirmButtonColor: '#2365af',
    cancelButtonColor: '#d33',
    background: '#fff',
    color: "#fff",
  });

  // If user confirms, proceed with the download
  if (result.isConfirmed) {
    try {
      setLoading(true); // Start loading state
      const token = localStorage.getItem("bear_token");
      const url = `${process.env.REACT_APP_API_URL}small-admins-selfemp-form-16B-PDF?smalladmins_id=${broker_id}&emp_id=${userid}`;

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: "application/pdf",
        },
        responseType: "blob",
      });

      if (response.headers["content-type"] === "application/pdf") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const fileURL = window.URL.createObjectURL(blob);
        window.open(fileURL, "_blank"); // Open in a new tab
//         Swal.fire(
// {
//           title: "Are you sure?",
//     text: "Your Form 16A has been downloaded.",
//     icon: "warning",
//     showCancelButton: true,
//     confirmButtonText: "Yes, download it!",
//     cancelButtonText: "No, cancel",
//        } );
      } else {
        console.error("Response is not a PDF file");
      }
    } catch (error) {
      console.error("Error fetching the PDF:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while loading the PDF file.",
      });
    } finally {
      setLoading(false); // Stop loading state
    }
  } else {
    // User clicked 'Cancel'
    // Swal.fire("Cancelled", "The download was cancelled.", "info");
  }
};





  return (
    <>
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../assets/img/icons/spot-illustrations/corner-4.png)",
          }}
        />
        <div className="card-body position-relative">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Employee Report Analytics</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="row g-3 mb-3">
  {/* Download Form 16A */}
  <div className="col-md-6 col-xxl-3" style={{ height: '100%' }}>
  <a href="#" onClick={(e) => { e.preventDefault(); downloadFileA(); }} className="text-decoration-none">
    <div 
      className="card h-md-100 ecommerce-card-min-width"
 
    >
      <div className="card-header pb-0">
        <h5
          className="card-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Download Form 16A  
          <span>
            <FcDownload className="fs-4 text-primary" />
          </span>
        </h5>
      </div>
      <div className="card-body d-flex flex-column justify-content-end">
        <img src={`${pdf}`} alt="" />
      </div>
    </div>
  </a>
</div>



  {/* Download Form 16B */}
  <div className="col-md-6 col-xxl-3" style={{ height: '100%' }}>
  <a href="#" onClick={(e) => { e.preventDefault(); downloadFileB(); }} className="text-decoration-none">
    <div 
      className="card h-md-100 ecommerce-card-min-width"
 
    >
      <div className="card-header pb-0">
        <h5
          className="card-title"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          Download Form 16B  
          <span>
            <FcDownload className="fs-4 text-primary" />
          </span>
        </h5>
      </div>
      <div className="card-body d-flex flex-column justify-content-end">
        <img src={`${pdf}`} alt="" />
      </div>
    </div>
  </a>
</div>
</div>

    </>
  );
}
