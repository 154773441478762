import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { GoogleLogin } from 'react-google-login';
import { Spinner } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import Button from 'react-bootstrap/Button';

export default function Forgetpassword() {

  const [loading, setLoading] = useState(false);
  const [email, setemail] = useState('');
  console.warn(email)

  const navigate = useNavigate();

  const onInputChangeemail = (e) => {
    setemail(e.target.value);
  };



  const [logoData, setLogoData] = useState(null);
  console.warn(logoData)


  const [error, setError] = useState(null);




  useEffect(() => {
    // Fetch data from API
    axios
      .post("https://broker.swaninvestment.in/API/public/api/employee-logo-withoutlogin")
      .then((response) => {
        if (response.data.status === 1) {
          setLogoData(response.data); // Set the API response data if status is 1 (Success)
        } else {
          setError("Failed to fetch data.");
        }
        setLoading(false);
      })
      .catch((err) => {
        setError("Error fetching data");
      });
  }, []);

  const Submit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      if (!email) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Email is missing!",
          color: "white",
          background: 'transparent',
        });

        setLoading(false);
        return;
      }

      let url = `${process.env.REACT_APP_API_URL}employee-forgot-password`;
      // let url = `${process.env.REACT_APP_API_URL}small-admins-selfemp-send-forget-password-link`;

      const formData = new FormData();
      formData.append("email", email);

      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {

        if (response.data.status == 1) {
          console.warn(response.data.success);
          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: response.data.message,
            color: "white",
            background: 'transparent',
          });
          // toast.success(response.data.message);
          localStorage.setItem("email", email);
          setTimeout(() => {
            navigate('/Changepasssword');
          }, 1000);


        } else {
          toast.error(response.data.message);
        }
      })
        .finally(() => {
          // setTimeout(() =>{
          setLoading(false);
          // },5000);
        });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }

  };

  return (
    <div>
      <main className="main" id="top">
        <div className="">
          <img
            className="bg-auth-circle-shape"
            src="assets/img/icons/spot-illustrations/bg-shape.png"
            alt=""
            width={250}
          />
          <img
            className="bg-auth-circle-shape-2"
            src="assets/img/icons/spot-illustrations/shape-1.png"
            alt=""
            width={150}
          />
          <div className="card overflow-hidden z-1">
            <div className="card-body p-0">
              <div className="row g-0 h-100">
                <div className="col-md-5 text-center bgcolornew d-flex align-items-center">
                  <div>
                    <div
                      className="position-relative p-4 pt-md-5 pb-md-7"
                      data-bs-theme="light"
                    >
                      <div
                        className="bg-holder bg-auth-card-shape"
                        style={{
                          backgroundImage:
                            "url(assets/img/icons/spot-illustrations/half-circle.png)"
                        }}
                      />


                      {/*/.bg-holder*/}
                      <div className="z-1 position-relative">
                        <a
                          className="link-light mb-4 font-sans-serif fs-5 d-inline-block fw-bolder"
                          href="index.php"
                        >
                          <div>

                          </div>
                        </a>
                        {logoData && logoData.logo_url && logoData.data ? (
                          <div>
                            <img
                              src={`${logoData.logo_url}/${logoData.data}`} // Full logo URL
                              alt="Employee Logo"
                              style={{ width: "200px", height: "auto" }}
                            />
                            <p className='mt-5  text-start'>{logoData.short_desc}</p> {/* Display the short description */}
                          </div>
                        ) : (
                          <p>No logo data available</p>
                        )}

                      </div>
                    </div>
                    <div
                      className="mt-3 mb-4 mt-md-4 mb-md-5"
                      data-bs-theme="light"
                    >
                      <p className="mb-0 mt-4 mt-md-5 fs-10 fw-semi-bold text-dark opacity-75">
                        Read our{" "}
                        <a
                          className="text-decoration-underline text-dark"
                          href="#!"
                        >
                          terms
                        </a>{" "}
                        and{" "}
                        <a
                          className="text-decoration-underline text-dark"
                          href="#!"
                        >
                          conditions{" "}
                        </a>


                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-7 d-flex flex-center" style={{marginTop:"-200px"}}>
                  <div className="p-4 p-md-5 flex-grow-1">
                    <div className="text-center text-md-start">
                      <Link to='/' className="linkWithoutUnderline" style={{ position: 'absolute', top: '25px', right: '44px' }}>
                        <Button variant="primary">Back</Button>{' '}
                      </Link>
                      <h4 className="mb-0"> Forgot your password?</h4>
                      <p className="mb-4">
                        Enter your email and we'll send you a reset link.
                      </p>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-sm-8 col-md">
                        <form className="mb-3 input-style-1">
                          <input
                            value={email}
                            onChange={onInputChangeemail}
                            className="form-control"
                            type="email"
                            placeholder="Email address"
                            required=""
                          />
                          <div className="mb-3" />
                          <button
                            disabled={loading}
                            onClick={Submit}
                            to="/Changepasssword"
                            onclick="showAlert()"
                            className="btn btn-primary d-block w-100 mt-3"
                          >
                            Send OTP
                            {loading && <Spinner animation="border" style={{ width: '15px', height: '15px', marginLeft: '10px' }} />}
                          </button>
                        </form>
                        <a className="fs-10 text-600" href="#!">
                          I can't recover my account using this page
                          <span className="d-inline-block ms-1">→</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </main>
      <ToastContainer />

    </div>
  )
}
