import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { FaUserAlt, FaUniversity, FaMoneyCheckAlt, FaCalendarAlt, FaFileInvoiceDollar, FaArrowLeft } from 'react-icons/fa';
import { RiBankCardLine } from "react-icons/ri";
import { MdAttachEmail } from "react-icons/md";
import { IoCloudDone } from "react-icons/io5";

import { FaOrcid } from "react-icons/fa";


export default function Salaryslipvies() {
    const { id } = useParams();

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);
    const [baseurl, setBaseurl] = useState('');
    const [profilImage, setProfilImage] = useState('');

    const fetchData = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem("bear_token");
            const userid = localStorage.getItem("userid");
            const id = localStorage.getItem("id");
            // alert(id)
            const broker_id = localStorage.getItem("smalladmins_id");

            const formData = new FormData();
            formData.append("smalladmins_id", broker_id);
            formData.append("id", id);
            formData.append("emp_id", userid);

            const config = {
                method: "post",
                url: `${process.env.REACT_APP_API_URL}small_admins_self_tranx_payslip_view`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                },
                data: formData
            };

            const response = await axios(config);
            const data = response.data.data;
            setData(data);
            setLoading(false);

            setBaseurl(response.data.trnximageUrl);
            setProfilImage(response.data.image);
        } catch (error) {
            console.error("Error fetching data:", error);
            setError(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    const capitalizeWords = (str) => {
        if (!str) return ""; // return an empty string if str is undefined or null
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };


    return (
        <div>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className="mb-0">Employee Salary Details</h2>
                        </div>
                        <div className="col-lg-4 text-end">
                            <Link to='/Salaryslip' className="text-white">
                                <button className="btn btn-success">Back</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                {data && data.map((dataItem) => (
                    <>
                        <div className="col-lg-12 mb-3" key={dataItem.emp_id}>
                            <div className="card">
                                <div className="card-header">
                                    <h5 className="card-title mb-0">Salary Details</h5>
                                </div>
                                <div className="card-body">
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <th scope="row">Employee ID</th>
                                                <td style={{ color: 'green', fontWeight: 'bold' }}>#{dataItem.emp_id}</td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Employee Name</th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }} className='capitalize'>
                                                    <FaUserAlt className="me-2" /> {capitalizeWords(dataItem.emp_name)}
                                                </td>
                                            </tr>
                                           
                                            <tr>
                                                <th scope="row">Email  </th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }} className='capitalize'>
                                                    <MdAttachEmail className="me-2" /> {dataItem.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Transaction Id </th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }} className='capitalize'>
                                                    <FaOrcid className="me-2" /> {dataItem.trnx_id}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Basic Salary</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.basic_salary}
                                                </td>
                                            </tr>
                                           
                                         
                                            <tr>
                                                <th scope="row">Other Allowances</th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }}>
                                                    <IoCloudDone className="me-2" /> {dataItem.other_allowances}
                                                </td>
                                            </tr>


                                            <tr>
                                                <th scope="row">Hra</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.hra}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Arrears</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.arrears}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">phone Andother Aallowance</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.phone_andother_allowance}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Special Pay Training</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.special_pay_training}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Expected Bonus</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.expected_bonus}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Medical</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.medical}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th scope="row">Convence_allowance</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.convence_allowance}
                                                </td>
                                            </tr>


                                            <tr>
                                                <th scope="row">Net Payable Salary</th>
                                                <td style={{ color: 'teal', fontWeight: 'bold' }}>
                                                    ₹{dataItem.net_payable_salary}
                                                </td>
                                            </tr>
                                         
                                         

                                            <tr>
                                                <th scope="row">Salary Slip Date</th>
                                                <td style={{ color: 'red', fontWeight: 'bold' }}>
                                                    <FaCalendarAlt className="me-2" /> {dataItem.salary_slip_date}
                                                </td>
                                            </tr>

                                            <tr>
                                                <th scope="row">Bank Details</th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }}>
                                                    <FaUniversity className="me-2" /> {capitalizeWords(dataItem.bank_details)}
                                                </td>
                                            </tr>
                                           
                                           
                                            <tr>
                                                <th scope="row">Aadhar Number</th>
                                                <td style={{ color: 'blue', fontWeight: 'bold' }}>
                                                    <RiBankCardLine className="me-2" /> {capitalizeWords(dataItem.uan_number)}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                ))}
            </div>
        </div>
    );
}
