import React, { useState, useEffect } from "react";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams, Link } from "react-router-dom";
import Swal from "sweetalert2";
import { FaArrowLeft } from 'react-icons/fa';
import { MdReply } from 'react-icons/md';

export default function NotificationReply() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState(null);
  const [reply, setReply] = useState("");
  const [errors, setErrors] = useState({});
  const broker_id = localStorage.getItem("smalladmins_id");
  const userid = localStorage.getItem("userid");
  const notificationId = localStorage.getItem("notificationId");
  useEffect(() => {
    fetchNotificationData();
  }, []);

  const fetchNotificationData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");
      const formData = new FormData();
      formData.append('smalladmins_id', broker_id);
      formData.append('id', notificationId);
      formData.append('user_id', userid);
    
      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toemployee-notification-details`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: formData,
      };

      const response = await axios(config);
      setNotificationData(response.data.data);
      console.warn("notificationData",response.data.data)

      setLoading(false);
    } catch (error) {
      console.error("Error fetching notification data:", error);
      setLoading(false);
    }
  };

  const handleReplyChange = (e) => {
    setReply(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reply.trim() === "") {
      setErrors({ reply: "Reply cannot be empty" });
      return;
    }

    try {
      setLoading(true);
      const token = localStorage.getItem("bear_token");

      const formData = new FormData();
      formData.append('smalladmins_id', broker_id);
      formData.append('id', notificationId);
      formData.append('user_id', userid);
      formData.append("emp_replied", reply);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}super-admins-sent-toemployee-notification-reply`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      await axios(config);
      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Reply sent successfully",
        color:"white",
        background: 'transparent',
        timer: 2000,
      })
    
    } catch (error) {
      console.error("Error sending reply:", error);
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to send reply",
        color:"white",
  
      })

    }
  };
  const formatDate = (dateString) => {

    const [day, month, year] = dateString.split('-').map(Number);
    
 
    const date = new Date(year, month - 1, day); 
    

    let hours = date.getHours();
    const minutes = date.getMinutes();
    

    const ampm = hours >= 12 ? 'PM' : 'AM';
    
    hours = hours % 12;
    hours = hours ? hours : 12; 
    

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    
  
    return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${formattedMinutes} ${ampm}`;
  };

  const removeHtmlTags = (text) => text.replace(/<[^>]*>/g, '');


  return (
    <div className="container">
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-8">
              <h3 className="mb-0">Notification Reply</h3>
            </div>
            <div className="col-lg-4 text-end">
              <Link to="/Notifications" className="btn btn-success">
                <FaArrowLeft /> Back
              </Link>
            </div>
          </div>
        </div>
      </div>

      <>
      {loading ? (
          <div className="text-center">

            <Spinner animation="border" />
          </div>
        ) : (
          notificationData && Array.isArray(notificationData) && notificationData.length > 0 ? (
            notificationData.map((item, index) => (
              <div className="card" key={index}>
                <div className="card-header bg-primary ">
                  <h5 className="card-title mb-0 text-white">Notification Details</h5>
                </div>
                <div className="card-body">
                  <div class="message">
                    <div class="message-wrapper">

                      <div class="message-content"> <span className="superadmin">Message </span><span className="margin-bottom capitalize"  dangerouslySetInnerHTML={{ __html: item.message || 'N/A' }}></span> </div>
                      <div class="message-options">
                        <span class="message-date">{formatDate(item.date) || 'N/A'}</span>
                         </div>
                    </div>


                  </div>
                  {item.super_admin_replied &&
                  <div class="message">
                    <div class="message-wrapper">

                      <div class="message-content"> <span className="superadmin">Super Admin </span><span className="capitalize">{removeHtmlTags(item.super_admin_replied || 'N/A')}</span></div>
                    </div>


                  </div>
                    }
                  <div class="message self">
                    <div class="message-wrapper">
                      <div class="message-content capitalize"><span>{item.emp_replied || 'N/A'}</span></div>
                    </div>


                  </div>
                  {/* <table className="table">
                    <tbody>
                      <tr>
                        <th scope="row">Message</th>
                        <td >
                          <div className="capitalize"  dangerouslySetInnerHTML={{ __html: item.message || 'N/A' }} />
                          <div style={{ fontSize: '12px' }}>{formatDate(item.date) || 'N/A'}</div>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Your Reply</th>
                        <td className="capitalize">{item.admin_replied || 'N/A'}</td>
                      </tr>
                      <tr>
                        <th scope="row">Super Admin Reply:</th>
                        <td className="capitalize">{item.super_admin_replied || 'N/A'}</td>
                      </tr>

                    </tbody>
                  </table> */}

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="reply" className="form-label">Your Reply</label>
                      <textarea
                        className={`form-control ${errors.reply ? "is-invalid" : ""}`}
                        id="reply"
                        rows="4"
                        value={reply}
                        onChange={handleReplyChange}
                      ></textarea>
                      {errors.reply && <div className="invalid-feedback">{errors.reply}</div>}
                    </div>
                    <button type="submit" className="btn btn-success">
                      <MdReply /> Send Reply
                    </button>
                  </form>
                </div>
              </div>
            ))
          ) : (
            <p>No notifications available.</p>
          )
        )}  
</>



    </div>
  );
}
