import React, { useState, useEffect } from "react";
import userprofile from "../assets/img/team/2.jpg";
import axios from "axios";
import Swal from "sweetalert2";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import $ from "jquery";
import { FaEdit } from "react-icons/fa";

import { BsFillCreditCardFill } from "react-icons/bs";
import { Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";


import withReactContent from "sweetalert2-react-content";
const Profile = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("bear_token");
  const [profileinfo, setProfileinfo] = useState();
  const [baseurl, setbaseurl] = useState();
  const [newImage, setNewImage] = useState();
  const [pan_image, setpan_image] = useState();
  const [uan_image, setuan_image] = useState();
  const [backuan_image, setBackuan_image] = useState();
  console.warn(pan_image)

  const [errors, setErrors] = useState({});
  const [fileimage, setFileimage] = useState();
  const credit = localStorage.getItem("credit");
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [formData, setFormData] = useState({
    name: "",
    joining_date: "",
    emp_lavel: "",
    email: "",
    personal_email: "",
    dept_name: "",
    roles_name: "",
    // joining_date: "",
    total_experience: "",
    confirmation_date: "",
    emp_salary: "",
    pre_work_exp: "",
    emergency_mobile: "",
    alternate_mobile: "",
    aadhar_no: "",
    permanant_address: "",
    current_address: "",
    profile: "",
    manager_name: "",
    designation: "",
    emp_lavel: "",
    dept_id: "",
    reporting_manager_id: "",
    mobile: "",
    dob: "",
    gender: "",
    pan_number: "",
    bank_ac_no: "",
    pf_no: "",
    esi_no: "",



  });
  const [editedFields, setEditedFields] = useState({});
  const [error, setError] = useState({})



  useEffect(() => {
    // Initialize formData with profileinfo values
    setFormData(profileinfo || {});
  }, [profileinfo]);




  const handleChange = (e) => {
    const { name, value } = e.target;


    // if (name === "alternate_mobile" && !/^\d*$/.test(value)) {

    //   return;
    // }







    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileimage(URL.createObjectURL(file));
      setNewImage(file);
      setFormData({
        ...formData,
        profile: "",
      });
    }
  };


  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");

      const formData = new FormData();
      formData.append("smalladmins_id", broker_id);

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}show-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      };

      const response = await axios(config);

      const data = response.data.data;
      const name = response.data.data.name;
      localStorage.setItem('name', name);


      const data1 = response.data.image_url;
      // const name = response.data.profile;
      const panname = response.data.data.pan_image;
      setpan_image(panname);
      const uan_image = response.data.data.uan_image;
      setuan_image(uan_image);
      const back_uan_image = response.data.data.back_uan_image;
      setBackuan_image(back_uan_image);


      setbaseurl(data1)
      setProfileinfo(data);
      setLoading(false)
      localStorage.setItem("profileimage", data[0].profile);


      setFileimage(null);

    } catch (error) {
      console.warn("daat", error.response)
      console.error("Error fetching user profile:", error.response);
      console.warn(error.response)
      if (error.response) {
        console.error("Error response data:", error.response.data);
        // Handle Unauthorized case
        if (error.response && error.response.statusText === "Unauthorized") {
          localStorage.clear();
          navigate("/");
        }

      } else {
        // Generic error handling
        console.error("Error message:", error.message || "An unknown error occurred.");
      }

    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("bear_token");
      const broker_id = localStorage.getItem("smalladmins_id");
      let formDataToUpdate = new FormData();

      formDataToUpdate.append("smalladmins_id", broker_id);
      formDataToUpdate.append("name", formData.name);
      formDataToUpdate.append("email", formData.email);
      formDataToUpdate.append("personal_email", formData.personal_email);
      formDataToUpdate.append("dept_name", formData.dept_name);
      formDataToUpdate.append("manager_name", formData.manager_name);
      formDataToUpdate.append("roles_name", formData.roles_name);
      formDataToUpdate.append("joining_date", formData.joining_date);
      formDataToUpdate.append("emp_lavel", formData.emp_lavel);
      formDataToUpdate.append("total_experience", formData.total_experience);
      formDataToUpdate.append("confirmation_date", formData.confirmation_date);
      formDataToUpdate.append("pre_work_exp", formData.pre_work_exp);
      formDataToUpdate.append("alternate_mobile", formData.alternate_mobile);
      formDataToUpdate.append("emergency_mobile", formData.emergency_mobile);
      formDataToUpdate.append("aadhar_no", formData.aadhar_no);
      formDataToUpdate.append("permanant_address", formData.permanant_address);
      formDataToUpdate.append("current_address", formData.current_address);
      formDataToUpdate.append("gender", formData.gender);
      formDataToUpdate.append("marital_status", formData.marital_status);
      formDataToUpdate.append("mobile", formData.mobile);
      formDataToUpdate.append("pan_number", formData.pan_number);
      formDataToUpdate.append("bank_ac_no", formData.bank_ac_no);
      formDataToUpdate.append("pf_no", formData.pf_no);
      formDataToUpdate.append("esi_no", formData.esi_no);
      formDataToUpdate.append("aadhar_no", formData.aadhar_no);
      formDataToUpdate.append("status", '1');

      if (newImage) {
        formDataToUpdate.append("profile", newImage);
        const reader = new FileReader();
        reader.onload = function () {
          localStorage.setItem("profile_image", reader.result);
        };
        reader.readAsDataURL(newImage);
      } else {
        const storedImage = localStorage.getItem("profile_image");
        if (storedImage) {
          const blob = await (await fetch(storedImage)).blob();
          formDataToUpdate.append("profile", blob);
        }
      }

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}update-employee-Profile`,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
        data: formDataToUpdate,
      };

      const response = await axios(config);
      const data = response.data;

      if (data.status === 1) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({

          icon: 'success',
          title: <strong>Profile Updated Successfully!</strong>,
          text: response.data.message,
          color: "white",
          background: 'transparent',
          // timer: 2000,
        })
          .then(() => {
            window.location.reload();
          });
        getProfile();
      } else if (data.status === 0) {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: <strong>{data.message}</strong>,
          icon: "error",
          color: "white",
          background: 'transparent',
        })

      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: <strong>{data.message}</strong>,
          icon: "error",
          color: "white",
          background: 'transparent',
        })
      }
      getProfile();

    } catch (error) {


      console.error("Error updating user profile:", error);
    }
  };


  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $("#imagePreview").css(
          "background-image",
          "url(" + e.target.result + ")"
        );
        $("#imagePreview").hide();
        $("#imagePreview").fadeIn(650);
      };
      reader.readAsDataURL(input.files[0]);
    }
  }
  $("#imageUpload").change(function () {
    readURL(this);
  });
  var $count = 0;

  var $eb = $('.edit-button');
  var $ei = $('.editable-input');
  var $ec = $('.editable-cell');

  //Editable input fields
  $eb.on('click', function () {
    $count++
    if ($count < 2) {
      $ei.prop('readonly', false).focus();
      $ei.prop('placeholder', '');
      $ei.val('');
      $(this).addClass('hide');
      $ec.addClass('editing');
    } else {
      $ei.prop('readonly', false).focus();
      $eb.addClass('hide');
      $ec.addClass('editing');
    }
  });


  $ei.on('blur', function () {
    $eb.removeClass('hide');
    $ei.prop('readonly', true);
    $ec.removeClass('editing');
  });


  if (loading) {
    return <div>
      <div id="preloader">
        <div id="loading-center">
          <div className="loader">
            <div className="waviy">
              <img
                src="https://swaninvestment.in/frontant/assets/img/loader.png"
                alt="Loader image"
              />
            </div>
          </div>
        </div>
      </div>
    </div>;
  }

  return (
    <div className="page">
      <div className="">
        {/* <form > */}
        <div className="d-flex justify-content-start">
          <div className="card width_30">
            <div className="card-header">

              <div className="avatar-upload">
                <div className="avatar-edit">

                  <label htmlFor="profile" className="custom-file-upload ">
                    <FaEdit className="edit-icon" />
                    <input
                      id="profile"
                      type="file"
                      name="profile"
                      onChange={handleImageChange}
                      style={{
                        position: "absolute",
                        opacity: 0,
                        top: 0,
                        left: 0,
                        cursor: "pointer",
                      }}
                    />
                  </label>
                </div>
                <div className="avatar-preview">
                  <div id="imagePreview" >
                    <img
                      className="rounded-circle imagbeprofile"
                      id="profileimage"
                      src={
                        fileimage
                          ? fileimage
                          : formData.profile
                            ? `${baseurl}/${formData.profile}`
                            : userprofile
                      }
                      alt="profile"
                    />


                  </div>
                </div>
              </div>


              <h4 className="mb-1 text-center editable-cell">
                <div className="editable-field">
                  <input
                    className="editable-input w-100 text-center"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}

                  />
                  <FaEdit className="edit-button" />
                  {errors.name && (
                    <div className="text-danger">{errors.name}</div>
                  )}

                </div>


                <span
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title="Verified"
                >
                  <small
                    className="fa fa-check-circle text-primary"
                    data-fa-transform="shrink-4 down-2"
                  />
                </span>
              </h4>
              {/* <h5 className="fs-9 fw-normal text-center">Worker</h5> */}
            </div>

            {/* <button
                className={activeTab === 2 ? "tablinks active" : "tablinks"}
                onClick={() => handleTabClick(2)}
              >
                Professional Details
              </button>
              <button
                className={activeTab === 3 ? "tablinks active" : "tablinks"}
                onClick={() => handleTabClick(3)}
              >
                Contact Details
              </button> */}
          </div>
          {/* {profileinfo && ( */}
          <div
            id="Profile"
            className="tabcontent"
            style={{ display: activeTab === 1 ? "block" : "none" }}
          >

            <div className="card mb-2">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0 "> Profile</h3>
                  {/* <Button className="gap-1 d-flex justify-content-end align-items-center" variant="info"><BsFillCreditCardFill />
                    {credit}</Button>{' '} */}


                  {/* <button
                    className="btn btn-falcon-primary btn-sm px-3"
                    type="button"
                  >
                    edit
                  </button> */}
                </div>
              </div>
            </div>

            <div className="card">

              <div className="d-flex justify-content-between align-items-center" style={{ paddingLeft: "10px", paddingTop: "10px" }}>
                <h5 className="mb-0 "> Personal Information</h5>
              </div>


              <div className="card-body">
                <div className="row g-3 mb-2">

                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Email">
                        Email
                      </label>
                      <input
                        className="form-control"
                        id="Email"
                        type="Email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="PersonalEmail">
                        Personal Email
                      </label>
                      <input
                        className="form-control"
                        id="PersonalEmail"
                        type="email"
                        name="personal_email"
                        value={formData.personal_email}
                        onChange={handleChange}
                      // 
                      />
                      {errors.personal_email && (
                        <div className="text-danger">{errors.personal_email}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-lg-6 form_details">

                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="mobile"
                      >
                        Mobile Number
                      </label>
                      <input
                        className="form-control"
                        id="mobile"
                        type="text"
                        name="mobile"
                        value={formData.mobile && formData.mobile.slice(0, 10)}
                        onChange={handleChange}
                        pattern="[0-9]*"
                      />


                    </div>
                  </div>

                  <div className="col-lg-6 form_details">

                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="Alternativemobilenumber"
                      >
                        Alternative Mobile Number
                      </label>
                      <input
                        className="form-control"
                        id="Alternativemobilenumber"
                        type="text"
                        name="alternate_mobile"
                        value={formData.alternate_mobile && formData.alternate_mobile.slice(0, 10)}
                        onChange={handleChange}
                        pattern="[0-9]*"
                      />


                    </div>
                  </div>
                  <div className="col-lg-6 form_details">

                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="dob"
                      >
                       Date of Birth
                      </label>
                      <input
                        className="form-control"
                        id="dob"
                        // type="date"
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        pattern="[0-9]*"
                      />


                    </div>
                  </div>
                  <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="gender">
                    Gender <span className="login-danger"></span>
                  </label>
                  <select
                    className={`form-select ${errors.gender ? 'input-error' : ''}`}
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                    style={{
                      color: formData.gender === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value=" " disabled>Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6 form_details mt-3">
                <div className="form-group local-forms">
                  <label className="form-label" htmlFor="marital_status">
                    Marital Status <span className="login-danger"></span>
                  </label>
                  <select
                    className={`form-select ${errors.marital_status ? 'input-error' : ''}`}
                    id="marital_status"
                    name="marital_status"
                    value={formData.marital_status}
                    onChange={handleChange}
                    style={{
                      color: formData.marital_status === " " ? "#b0bcc7" : "#000000", // Secondary color for placeholder, dark color for other options
                    }}
                  >
                    <option value=" " disabled>Select</option>
                    <option value="Single">Single</option>
                    <option value="Married">Married</option>
                  </select>
                </div>
              </div>



                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="emp_id">
                        Employee ID
                      </label>
                      <input
                        className="form-control"
                        id="emp_id"
                        type="text"
                        // defaultValue={profileinfo.emp_id}
                        Value={formData.emp_id}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Role">
                        Role
                      </label>
                      <input
                        className="form-control"
                        id="Role"
                        type="text"
                        name='roles_name'
                        value={formData.roles_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>


                 
              
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Work Information</h5>
                  </div>




                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      > Department name
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="PreviousExperience"
                        type="text"
                        name="pre_work_exp"
                        value={formData.dept_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>
                 
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      > Manager  Name
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="PreviousExperienc"
                        type="text"
                        name="pre_work_exp"
                        value={formData.manager_name}
                        onChange={handleChange}
                        disabled

                      />
                    </div>

                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Joining">
                        Joining Date
                      </label>
                      <input
                        className="form-control"
                        id="Joining"
                        // type="date"
                        name="joining_date"
                        value={formData.joining_date}
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Experience">
                        Total Experience
                      </label>
                      <input
                        className="form-control"
                        id="Experience"
                        type="text"
                        name="total_experience"
                        value={formData.total_experience
                        }
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Confirmation">
                        Confirmation Date Number
                      </label>
                      <input
                        className="form-control"
                        id="Confirmation"
                        // type="date"
                        name="confirmation_date"
                        value={formData.confirmation_date}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PreviousExperience"
                      >
                        Previous Work Experience (Optional)
                      </label>
                      <input
                        className="form-control"
                        id="PreviousExperienc"
                        type="text"
                        name="pre_work_exp"
                        value={formData.pre_work_exp}
                        onChange={handleChange}

                      />
                    </div>
                  </div>



                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Salary  Details</h5>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Joining">
                        Lavel
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="emp_lavel"
                        // type="date"
                        name="emp_lavel"
                        value={formData.emp_lavel}
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Experience">
                        Designation
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="designation"
                        type="text"
                        name="designation"
                        value={formData.designation
                        }
                        onChange={handleChange}
                        disabled

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Confirmation">
                        Salary
                      </label>
                      <input
                        className="form-control"
                        id="emp_salary"
                        // type="date"
                        name="emp_salary"
                        value={formData.emp_salary}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Address Information</h5>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="Address">
                        Current Address
                      </label>
                      <textarea
                        className="form-control"
                        id="Address"
                        type="text"
                        name="current_address"
                        value={formData.current_address}
                        onChange={handleChange}
                      />

                    </div>
                  </div>

                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label
                        className="form-label"
                        htmlFor="PermanentAddress"
                      >
                        Permanent Address
                      </label>
                      <textarea
                        className="form-control"
                        id="PermanentAddress"
                        type="text"
                        name="permanant_address"
                        value={formData.permanant_address}
                        onChange={handleChange}
                      />

                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Bank Information</h5>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="bank_ac_no">
                      Bank Account Number
                      </label>
                      <textarea
                        className="form-control"
                        id="bank_ac_no"
                        type="text"
                        name="bank_ac_no"
                        value={formData.bank_ac_no}
                        onChange={handleChange}
                      />

                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="acc_holder_name">
                      Account Holder Name
                      </label>
                      <textarea
                        className="form-control"
                        id="acc_holder_name"
                        type="text"
                        name="acc_holder_name"
                        value={formData.acc_holder_name}
                        onChange={handleChange}
                        disabled
                      />

                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="bank_branch">
                      Bank Branch
                      </label>
                      <textarea
                        className="form-control"
                        id="bank_branch"
                        type="text"
                        name="bank_branch"
                        value={formData.bank_branch}
                        onChange={handleChange}
                        disabled
                      />

                    </div>
                  </div>

                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 "> Identification Information</h5>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="pan_number">
                      PAN Number
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="pan_number"
                        // type="date"
                        name="pan_number"
                        value={formData.pan_number}
                        onChange={handleChange}
                     
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="aadhar_no">
                      Aadhaar Number
                      </label>
                      <input
                        className="form-control capitalizes"
                        id="aadhar_no"
                        type="text"
                        name="aadhar_no"
                        value={formData.aadhar_no
                        }
                        onChange={handleChange}

                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <Button variant="primary" onClick={handleShow}>
                    View Addhar And Pan Card Photo
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>                    Addhar & Pan Card View
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>  
                          <div class="row">
                        


                        <div className="col-lg-6 form_details">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="PreviousExperience"
                            >
                              Aadhaar Card Front Image
                            </label>

                            <div className="image_width">
                              {uan_image == null ?

                                <>
                                  <img
                                    src={userprofile} // Use the imported image here
                                   
                                    alt="user_profile"
                                    className="img-fluid  fs-6 me-1"
                                  />
                                </>
                                :



                                <img
                                  src={`${baseurl}/${uan_image}`} alt="plan_image" className="img-fluid rounded-circle fs-6  me-1" />
                              }
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 form_details">
                          <div className="form-group">
                            <label
                              className="form-label"
                              htmlFor="PreviousExperience"
                            >
                              Aadhaar Card Back Image
                            </label>

                            <div className="image_width">
                              {backuan_image == null ?

                                <>
                                  <img
                                    src={userprofile} // Use the imported image here
                                   
                                    alt="user_profile"
                                    className="img-fluid  fs-6 me-1"
                                  />
                                </>
                                :



                                <img
                                  src={`${baseurl}/${backuan_image}`} alt="a_image" className="img-fluid rounded-circle fs-6  me-1" />
                              }
                            </div>
                          </div>
                        </div>
                        <hr className="mt-3 mb-3"></hr>
                        <div className="col-lg-6 form_details">
                        <div className="form-group">
                          <label
                            className=""
                            htmlFor="PreviousExperience"
                          >
                            Pan  Card Image
                          </label>


                          <div className="image_width">
                            {pan_image == null ?

                              <>
                                <img
                                  src={userprofile} // Use the imported image here
                                 
                                  alt="user_profile"
                                  className="img-fluid  fs-6 me-1"
                                />
                              </>
                              :



                              <img
                                src={`${baseurl}/${pan_image}`} alt="plan_image" className="img-fluid rounded-circle fs-6  me-1" />
                            }

                          </div>
                        </div>
                      </div>
                          </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                          Close
                        </Button>
                      
                      </Modal.Footer>
                    </Modal>

                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="pf_no">
                      PF Number
                      </label>
                      <input
                        className="form-control"
                        id="pf_no"
                        type="text"
                        name="pf_no"
                        value={formData.pf_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="esi_no">
                      ESI Number
                      </label>
                      <input
                        className="form-control"
                        id="esi_no"
                        type="text"
                        name="esi_no"
                        value={formData.esi_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="pf_no">
                      PF Number
                      </label>
                      <input
                        className="form-control"
                        id="pf_no"
                        type="text"
                        name="pf_no"
                        value={formData.pf_no}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 form_details">
                    <div className="form-group local-forms">
                      <label className="form-label" htmlFor="upi_id">
                      UPI Id
                      </label>
                      <input
                        className="form-control"
                        id="upi_id"
                        type="text"
                        name="upi_id"
                        value={formData.upi_id}
                        disabled
                        // onChange={handleChange}
                      />
                    </div>
                  </div>
               
                  <div className="col-12 d-flex justify-content-end">
                    <button onClick={handleSubmit} className="btn btn-primary">
                      Update
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      {/* </form> */}
    </div>
  );
};
export default Profile;
